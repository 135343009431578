export const getCenterCourtDateCardData = () => {
  return [
    {
      date: "Mon, Aug 28, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$1,050</span>`,
      title: `Session 1 <br> Men's and Women's Round 1`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Monday",
      modalDate: "28",
      modalMonth: "August",
      info: {
        day: "1",
        session: "1",
        points: ["Men's 1st Round", "Women's 1st Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId: "hospitality-first-round-mini-plans-m2ytgkq-3713595",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          // offerId: "MDgyODIzRF81X0hEXzFfMQ",
          // subOfferId: "fv-offer--20220601-WBE-607",
          ticketMasterLink:
            "https://am.ticketmaster.com/usopentennis/quickbuy?p1=MDgyODIzRF81X0hEXzFfMQ==",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Mon, Aug 28, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$914</span>`,
      title: `Session 2 <br> Men's and Women's Round 1`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Monday",
      modalDate: "28",
      modalMonth: "August",
      info: {
        day: "1",
        session: "2",
        points: ["Men's 1st Round", "Women's 1st Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId: "hospitality-first-round-mini-plans-m2ytgkq-3713595",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "Overlookus5820230530-2147412",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Tue, Aug 29, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$1,150</span>`,
      title: `Session 3 <br> Men's and Women's Round 1`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Tuesday",
      modalDate: "29",
      modalMonth: "August",
      info: {
        day: "2",
        session: "3",
        points: ["Men's 1st Round", "Women's 1st Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId: "hospitality-first-round-mini-plans-m2ytgkq-3713595",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          // offerId: "Overlookus5820230530-2147413",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
          ticketMasterLink:
            "https://am.ticketmaster.com/usopentennis/quickbuy?p1=MDgyOTIzRF81X0hCXzFfMA==",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Tue, Aug 29, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$914</span>`,
      title: `Session 4 <br> Men's and Women's Round 1`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Tuesday",
      modalDate: "29",
      modalMonth: "August",
      info: {
        day: "2",
        session: "4",
        points: ["Men's 1st Round", "Women's 1st Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId: "hospitality-first-round-mini-plans-m2ytgkq-3713595",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "Overlookus5820230530-2147414",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Wed, Aug 30, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$1,150</span>`,
      title: `Session 5 <br> Men's and Women's Round 2`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Wednesday",
      modalDate: "30",
      modalMonth: "August",
      info: {
        day: "3",
        session: "5",
        points: ["Men's 2nd Round", "Women's 2nd Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId: "hospitality-second-round-mini-plans-m811mlp-05323e1",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          // offerId: "Overlookus5820230530-2147415",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
          ticketMasterLink:
            "https://am.ticketmaster.com/usopentennis/quickbuy?p1=MDgzMDIzRF81X0g4XzFfMA==",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Wed, Aug 30, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$914</span>`,
      title: `Session 6 <br> Men's and Women's Round 2`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Wednesday",
      modalDate: "30",
      modalMonth: "August",
      info: {
        day: "3",
        session: "6",
        points: ["Men's 2nd Round", "Women's 2nd Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId: "hospitality-second-round-mini-plans-m811mlp-05323e1",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "Overlookus5820230530-2147416",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Thu, Aug 31, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$1,150</span>`,
      title: `Session 7 <br> Men's and Women's Round 2`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Thursday",
      modalDate: "31",
      modalMonth: "August",
      info: {
        day: "4",
        session: "7",
        points: ["Men's 2nd Round", "Women's 2nd Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId: "hospitality-second-round-mini-plans-m811mlp-05323e1",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          // offerId: "Overlookus5820230530-2147417",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
          ticketMasterLink:
            "https://am.ticketmaster.com/usopentennis/quickbuy?p1=MDgzMTIzRF81X0g2XzFfMA==",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Thu, Aug 31, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$1,028</span>`,
      title: `Session 8 <br> Men's and Women's Round 2`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Thursday",
      modalDate: "31",
      modalMonth: "August",
      info: {
        day: "4",
        session: "8",
        points: ["Men's 2nd Round", "Women's 2nd Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId: "hospitality-second-round-mini-plans-m811mlp-05323e1",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "Overlookus5820230530-2147418",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Fri, Sep 1, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$1,525</span>`,
      title: `Session 9 <br> Men's and Women's Round 3`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Friday",
      modalDate: "1",
      modalMonth: "September",
      info: {
        day: "5",
        session: "9",
        points: ["Men's 3rd Round", "Women's 3rd Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId:
            "hospitality-friday-labor-day-weekend-mini-plan-eu6y33t-72c6c36",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          // offerId: "Overlookus5820230530-2147419",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
          ticketMasterLink:
            "https://am.ticketmaster.com/usopentennis/quickbuy?p1=MDkwMTIzRF81X0hMXzFfMA==",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Fri, Sep 1, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$1,375</span>`,
      title: `Session 10 <br> Men's and Women's Round 3`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Friday",
      modalDate: "1",
      modalMonth: "September",
      info: {
        day: "5",
        session: "10",
        points: ["Men's 3rd Round", "Women's 3rd Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId:
            "hospitality-friday-labor-day-weekend-mini-plan-eu6y33t-72c6c36",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          // offerId: "Overlookus5820230530-21474110",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
          ticketMasterLink:
            "https://am.ticketmaster.com/usopentennis/quickbuy?p1=MDkwMTIzRV81X0hKXzFfMA==",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Sat, Sep 2, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$857</span>`,
      title: `Session 11 <br> Men's and Women's Round 3`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Saturday",
      modalDate: "2",
      modalMonth: "September",
      info: {
        day: "6",
        session: "11",
        points: ["Men's 3rd Round", "Women's 3rd Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId:
            "hospitality-saturday-labor-day-weekend-mini-plan-2kuv2sh-a51516a",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "Overlookus5820230530-21474111",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Sat, Sep 2, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$1,375</span>`,
      title: `Session 12 <br> Men's and Women's Round 3`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Saturday",
      modalDate: "2",
      modalMonth: "September",
      info: {
        day: "6",
        session: "12",
        points: ["Men's 3rd Round", "Women's 3rd Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId:
            "hospitality-saturday-labor-day-weekend-mini-plan-2kuv2sh-a51516a",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          // offerId: "Overlookus5820230530-21474112",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
          ticketMasterLink:
            "https://am.ticketmaster.com/usopentennis/quickbuy?p1=MDkwMjIzRV81X0hKXzFfMA==",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Sun, Sep 3, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$1,085</span>`,
      title: `Session 13 <br> Men's and Women's Round of 16`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Sunday",
      modalDate: "3",
      modalMonth: "September",
      info: {
        day: "7",
        session: "13",
        points: ["Men's Round of 16", "Women's Round of 16"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId:
            "hospitality-sunday-labor-day-weekend-mini-plan-hw4p8jb-25eb21b",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "Overlookus5820230530-21474113",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Sun, Sep 3, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$1,375</span>`,
      title: `Session 14 <br> Men's and Women's Round of 16`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Sunday",
      modalDate: "3",
      modalMonth: "September",
      info: {
        day: "7",
        session: "14",
        points: ["Men's Round of 16", "Women's Round of 16"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId:
            "hospitality-sunday-labor-day-weekend-mini-plan-hw4p8jb-25eb21b",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          // offerId: "Overlookus5820230530-21474114",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
          ticketMasterLink:
            "https://am.ticketmaster.com/usopentennis/quickbuy?p1=MDkwMzIzRV81X0hKXzFfMA==",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Mon, Sep 4, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$1,525</span>`,
      title: `Session 15 <br> Men's and Women's Round of 16`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Monday",
      modalDate: "4",
      modalMonth: "September",
      info: {
        day: "8",
        session: "15",
        points: ["Men's Round of 16", "Women's Round of 16"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId:
            "hospitality-monday-labor-day-weekend-mini-plan-6e1bpaf-d979964",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          // offerId: "Overlookus5820230530-21474115",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
          ticketMasterLink:
            "https://am.ticketmaster.com/usopentennis/quickbuy?p1=MDkwNDIzRF81X0hNXzFfMA==",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Mon, Sep 4, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$1,375</span>`,
      title: `Session 16 <br> Men's and Women's Round of 16`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Monday",
      modalDate: "4",
      modalMonth: "September",
      info: {
        day: "8",
        session: "16",
        points: ["Men's Round of 16", "Women's Round of 16"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId:
            "hospitality-monday-labor-day-weekend-mini-plan-6e1bpaf-d979964",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          // offerId: "Overlookus5820230530-21474116",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
          ticketMasterLink:
            "https://am.ticketmaster.com/usopentennis/quickbuy?p1=MDkwNDIzRV81X0hMXzFfMA==",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Tue, Sep 5, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$914</span>`,
      title: `Session 17 <br> Men's and Women's Quarterfinals`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Tuesday",
      modalDate: "5",
      modalMonth: "September",
      info: {
        day: "9",
        session: "17",
        points: ["Men's Quarterfinals", "Women's Quarterfinals"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId: "hospitality-quarter-finals-mini-plan-evf1nqt-bf08b55",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "Overlookus5820230530-21474117",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Tue, Sep 5, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$1,600</span>`,
      title: `Session 18 <br> Men's and Women's Quarterfinals`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Tuesday",
      modalDate: "5",
      modalMonth: "September",
      info: {
        day: "9",
        session: "18",
        points: ["Men's Quarterfinals", "Women's Quarterfinals"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId: "hospitality-quarter-finals-mini-plan-evf1nqt-bf08b55",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "Overlookus5820230530-21474118",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Wed, Sep 6, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$1,143</span>`,
      title: `Session 19 <br> Men's and Women's Quarterfinals`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Wednesday",
      modalDate: "6",
      modalMonth: "September",
      info: {
        day: "10",
        session: "19",
        points: ["Men's Quarterfinals", "Women's Quarterfinals"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId: "hospitality-quarter-finals-mini-plan-evf1nqt-bf08b55",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "Overlookus5820230530-21474119",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Wed, Sep 6, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$1,657</span>`,
      title: `Session 20 <br> Men's and Women's Quarterfinals`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Wednesday",
      modalDate: "6",
      modalMonth: "September",
      info: {
        day: "10",
        session: "20",
        points: ["Men's Quarterfinals", "Women's Quarterfinals"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId: "hospitality-quarter-finals-mini-plan-evf1nqt-bf08b55",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "Overlookus5820230530-21474120",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Thu, Sep 7, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$3,825</span>`,
      title: `Session 21 <br> Women's Semifinals`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Thursday",
      modalDate: "7",
      modalMonth: "September",
      info: {
        day: "11",
        session: "21",
        points: ["Women's Semifinals"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId: "hospitality-finals-mini-plan-5ingu6u-fe16a1b",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          // offerId: "Overlookus5820230530-21474121",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
          ticketMasterLink:
            "https://am.ticketmaster.com/usopentennis/quickbuy?p1=MDkwNzIzRV81X0g5XzFfMA==",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Fri, Sep 8, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$3,175</span>`,
      title: `Session 22 <br> Men's Semifinals`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Friday",
      modalDate: "8",
      modalMonth: "September",
      info: {
        day: "12",
        session: "22",
        points: ["Men's Semifinals"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId: "hospitality-finals-mini-plan-5ingu6u-fe16a1b",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          // offerId: "Overlookus5820230530-21474122",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
          ticketMasterLink:
            "https://am.ticketmaster.com/usopentennis/quickbuy?p1=MDkwODIzRF81X0hKXzFfMA==",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Fri, Sep 8, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$2,171</span>`,
      title: `Session 23 <br> Men's Semifinals`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Friday",
      modalDate: "8",
      modalMonth: "September",
      info: {
        day: "12",
        session: "23",
        points: [
          // "Men's Doubles, Final or Mixed Doubles, Final /",
          "Men's, Semifinals",
        ],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId: "hospitality-finals-mini-plan-5ingu6u-fe16a1b",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "Overlookus5820230530-21474123",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Sat, Sep 9, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$2,056</span>`,
      title: `Session 24 <br> Women's Final`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Saturday",
      modalDate: "9",
      modalMonth: "September",
      info: {
        day: "13",
        session: "24",
        points: ["Women's Final"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId: "hospitality-finals-mini-plan-5ingu6u-fe16a1b",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "Overlookus5820230530-21474124",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Sun, Sep 10, 1:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$3,199</span>`,
      title: `Session 25 <br> Men's Final`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Sunday",
      modalDate: "10",
      modalMonth: "September",
      info: {
        day: "14",
        session: "25",
        points: ["Men's Final"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-overlook-at-the-us-open-multi-session-packages-c866184",
          subOfferId: "hospitality-finals-mini-plan-5ingu6u-fe16a1b",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "Overlookus5820230530-21474125",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
  ];
};
