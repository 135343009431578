<template>
  <div id="AmericanExpressClub">
    <HeroCarousel :heroCarouselData="heroCarouselData" />
    <div class="gradient"></div>
    <TicketPackages
      :ticketPackagesHeader="ticketPackagesHeader"
      :packages="packages"
      :ticketPackageImages="ticketPackageImages"
    />
    <!-- <div id="selectDateAnchor"></div>
    <SelectDate
      :dateCard="dateCard"
      dateCardTitle="American Express Center Court Club"
    /> -->
  </div>
</template>

<script>
import HeroCarousel from "@/components/patterns/HeroCarousel";
import TicketPackages from "@/components/patterns/TicketPackages";
import { getCenterCourtClubData } from "@/data/HeroCarousel/centerCourtClubData";
import { getTicketPackagesHeader } from "@/data/TicketPackages/centerCourtClubTicketPackagesData";
import SelectDate from "@/components/patterns/SelectDate";
import { getCenterCourtDateCardData } from "@/data/DateSelect/centerCourtDateCardData";
import axios from "axios";

export default {
  name: "AmericanExpressClub",
  components: {
    HeroCarousel,
    TicketPackages,
    SelectDate,
  },
  data() {
    return {
      heroCarouselData: getCenterCourtClubData(),
      // ticketPackagesHeader: getTicketPackagesHeader(),
      ticketPackagesHeader: {
        header:
          "<p>Treat guests to the best of both worlds.  The American&nbsp;Express&nbsp;Center&nbsp;Court&nbsp;Club is a one-of-a-kind club offering guests panoramic, up-close views from the largest, most all-inclusive suite in Arthur Ashe Stadium.</p>",
      },
      packages: null,
      ticketPackageImages: [
        "CenterCourtClub/ccc_the-details.jpg",
        "CenterCourtClub/ccc_the-cuisine-the-drinks.jpg",
        "CenterCourtClub/ccc_the-view.jpg",
      ],
      dateCard: getCenterCourtDateCardData(),
    };
  },
  mounted() {
    window.scrollTo(0, 0);

    var axel = Math.random() + "";
    var a = axel * 10000000000000;
    var axel2 = Math.random() + "";
    var b = axel2 * 10000000000000;

    let floodlightTag = document.createElement("script");
    let floodlightTag2 = document.createElement("script");
    floodlightTag.setAttribute(
      "src",
      "https://5526005.fls.doubleclick.net/activityi;src=5526005;type=usopen;cat=amexc0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=" +
        a +
        "?"
    );
    floodlightTag2.setAttribute(
      "src",
      "https://5526005.fls.doubleclick.net/activityi;src=5526005;type=usopen;cat=amexc00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1;num=" +
        b +
        "?"
    );
    document.body.appendChild(floodlightTag);
    document.body.appendChild(floodlightTag2);

    let pages = this.$store.state.epitch.ePitch.pages;
    // window.console.log('pages', pages)
    pages.forEach((page) => {
      if (page.id === "5") {
        this.packages = JSON.parse(page.copy);
      }
    });

    if (this.$store.state.epitch.ePitch.mode === "live") {
      const purl = this.$store.state.epitch.ePitch.purl;
      const epitchUrl = this.$store.state.epitch.url;
      axios.post(`${epitchUrl}/epitchApi/EpitchLiteTracking.php`, {
        method: "page",
        purl: purl,
        presentationId: window.presentationId,
        sessionDbId: window.sessionDbId,
        section: 5,
      });
    }

    let copy = this.$store.state.epitch.ePitch.pages;
    if (typeof copy !== "undefined") {
      copy.forEach((page) => {
        if (page.id === "5") {
          let description = JSON.parse(page.copy);
          let pageDescription = description.pageDescription;
          if (pageDescription) {
            this.ticketPackagesHeader.header = pageDescription;
          }
          // window.console.log('description', pageDescription)
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>