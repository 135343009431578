export const getCenterCourtClubData = () => {
  return [
    {
      carouselImages: [
        "CenterCourtClub/Carousel/ccc_gallery_0.jpg",
        "CenterCourtClub/Carousel/ccc_gallery_1.jpg",
        "CenterCourtClub/Carousel/ccc_gallery_2.jpg",
        "CenterCourtClub/Carousel/ccc_gallery_3.jpg",
        "CenterCourtClub/Carousel/ccc_gallery_4.jpg",
        // 'CenterCourtClub/Carousel/ccc_gallery_5.jpg',
        // 'CenterCourtClub/Carousel/ccc_gallery_6.jpg',
        // 'CenterCourtClub/Carousel/ccc_gallery_7.jpg',
        "CenterCourtClub/Carousel/ccc_gallery_8.jpg",
      ],
      title: "American Express",
      subTitle: "<i>Center Court Club<i>",
      // soldOut: true,
      bottomLinks: [
        {
          title: "gallery",
          link: "modal",
          icon: "gallery",
        },
        // {
        //   title: "buy now",
        //   link: "#",
        //   icon: "buy",
        // },
        // {
        //   title: "appointment",
        //   link: "appointment",
        //   icon: "appointment",
        // },
        // {
        //   title: "view packages",
        //   link: "#",
        //   fevo: "2021-deposit-for-hospitality-67n5ypc-81b7185",
        //   icon: "buy",
        // },
      ],
    },
  ];
};
